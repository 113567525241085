'use strict';

import { isObject } from '@bomgar/typescript-utils';

/**
 * Returns a URL-encoded string representation of objects with non-string properties.
 *
 * URLSearchParameters alone does not handle non-primitive properties like objects and arrays –
 * only Record<string, string|number> – so we must do extra work to ensure nested objects and arrays
 * are encoded properly.
 *
 * Does not support
 *     - complex object nesting, e.g. { foo: { bar: 'baz' } }.
 *     - complex array nesting, e.g. { foo: [ ['bar', 'baz'] ] }
 *     - Dates
 *
 * If you need any of that, use JSON encoding.
 *
 * @param {object} obj
 */
export function urlEncodeObject(obj) {
	const params = new URLSearchParams();

	for (const key in obj) {
		const value = obj[key];
		if (isObject(value)) {
			if (Array.isArray(value)) {
				value.forEach(arrValue =>
					params.append(`${key}[]`, arrValue)
				);
			} else {
				for (const innerKey in value) {
					params.append(`${key}[${innerKey}]`, value[innerKey]);
				}
			}
		} else {
			if (value !== undefined && value !== null) {
				params.append(key, value.toString());
			}
		}
	}

	return params.toString();
}
