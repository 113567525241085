/*global BG*/
'use strict';

import { isFiniteNumber, isPlainObject, isString, isBoolean, omit, uniq } from '@beyondtrust-sra/typescript-utils';
import { clientHintsSupported } from '@bomgar/user-agent-detector';
import { defaultPostOptions } from './defaultPostOptions';
import { makeUrl } from './makeUrl';
import { responseToJson } from './responseToJson';
import { urlEncodeObject } from './urlEncodeObject';

export const Core = (function() {
	/**
	 * @class
	 * @param {Session} session
	 */
	return function Core(session) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const that = this;
		const _session = session;
		const _sessionHost = session.getHost();
		const _uaParser = session.uaParser;

		let _platformId = _uaParser.platformId().then(platformId => _platformId = platformId);

		const _isIOS = _uaParser.isiOS();
		const _isAndroid = _uaParser.isAndroid();
		let instructionsContainer = null;
		let progressBarElem = null;
		let _closeOnFinish = true;
		let _allowDialogClose = true;
		const _isSameOrigin = _sessionHost === window.location.hostname;
		let _fallbackToFull = true;
		const _makeUrl = (path, query) => {
			return makeUrl(_session.getProtocol(), _sessionHost, path, query);
		};
		let removeDialog = null;

		/***************************************************************
		 * Browser methods
		 **************************************************************/

		/**
		 * Starts a browser session with the given options. Called from start_session.js
		 * @param {Object} options Determines how the session will be started, i.e. session
		 * key, issue, rep.
		 * @returns void
		 */
		this.startFullSession = function(options) {
			console.log('Starting full session from inside core');
			this.startSession(options);
		};

		/**
		 * Renders the custom attributes for a URL.
		 * @param {Object} options
		 * @returns string
		 */
		function renderAttributes(options) {
			if (!options.attributes) {
				return '';
			}

			const query = new URLSearchParams();

			for (const [ key, value ] of Object.entries(options.attributes)) {
				query.append('session.custom.' + key, value.toString());
			}

			return '&' + query.toString();
		}

		/**
		 * Renders the skills for a URL.
		 * @param {Object} options
		 * @returns string
		 */
		function renderSkills(options) {
			let skillsRendered = '';

			if (options.skills?.length > 0) {
				skillsRendered = '&session.skills=' + encodeURIComponent(options.skills.join(','));
			}

			return skillsRendered;
		}

		function showProgressBar() {
			// Wait a second before actually showing it, since it's just glitchy and distracting
			// if the thing we're waiting on happens in less than a second.
			const timeoutId = setTimeout(() => {
				if (progressBarElem) {
					progressBarElem.style.display = 'block';
				}
			}, 1000);

			return () => {
				clearTimeout(timeoutId);

				if (progressBarElem) {
					progressBarElem.style.display = 'none';
				}
			};
		}

		/**
		 *
		 * @private
		 * @param {any} options
		 * @returns {undefined}
		 */
		this.startSession = function(options) {
			const params = getSessionParams(options);

			if (isPlainObject(options.uiOptions)) {
				const uiOptions = options.uiOptions;

				if (isBoolean(uiOptions.closeOnFinish)) {
					_closeOnFinish = uiOptions.closeOnFinish;
				}

				if (isBoolean(uiOptions.allowDialogClose)) {
					_allowDialogClose = uiOptions.allowDialogClose;
				}

				if (isBoolean(uiOptions.fallbackToFullWindow)) {
					_fallbackToFull = options.uiOptions.fallbackToFullWindow;
				}
			}

			// Do nothing by default
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			let sessionStartFailed = () => {};

			if (typeof options.sessionStartFailed === 'function') {
				sessionStartFailed = options.sessionStartFailed;
			}

			progressBarElem = document.getElementById('bomgarProgressBar');

			if (!progressBarElem) {
				progressBarElem = document.createElement('div');
				progressBarElem.id = 'bomgarProgressBar';
				progressBarElem.className = 'bg-progress-bar';
				progressBarElem.role = 'progressbar';
				progressBarElem.ariaValuemin = '0';
				progressBarElem.ariaValuemax = '100';
				progressBarElem.style.display = 'none';

				const indeterminateContainer = document.createElement('div');
				indeterminateContainer.className = 'bg-progress-bar-indeterminate-container';
				progressBarElem.appendChild(indeterminateContainer);

				const valueElement = document.createElement('div');
				valueElement.className = 'bg-progress-bar-value';
				indeterminateContainer.appendChild(valueElement);
			}

			if (instructionsContainer === null) {
				instructionsContainer = document.createElement('div');
				instructionsContainer.className = 'bg-instructions-container';
			}

			const dialogContentDiv = document.createElement('div');
			dialogContentDiv.style.height = '100%';
			dialogContentDiv.style.width = '100%';
			dialogContentDiv.appendChild(progressBarElem);
			dialogContentDiv.appendChild(instructionsContainer);

			loadDialog(dialogContentDiv);

			const attributesRendered = renderAttributes(params);

			let fallbackUrlQuery = {
				api: 1,
				'g-public-errorType': 'minimal_html',
			};

			if (!_isSameOrigin && clientHintsSupported) {
				// Client Hints-capable browsers do not send all CH headers we need on
				// cross-origin requests, which means the server might not be able to tell what
				// platform we need if we don't tell it.
				fallbackUrlQuery['platform'] = _platformId;
			}

			if (!_isAndroid && !_isIOS) {
				fallbackUrlQuery['minimalInstructions'] = 1;
			}

			fallbackUrlQuery = {
				...fallbackUrlQuery,
				// Attributes are included later in attributesRendered
				...omit(params, 'attributes'),
			};

			const downloadParams = {
				sessionParameters: params,
				fallbackURL: _makeUrl('/download_client_connector', fallbackUrlQuery)
					+ attributesRendered,
			};

			if (!options.skipAuthenticationConfirm) {
				BG.ensureAuthenticated().then(success => {
					if (success) {
						options.skipAuthenticationConfirm = true;
						that.startSession(options);
					}
				});

				return false;
			}

			if (!!options.sessionKey && !options.skipConfirm) {
				const hideProgressBar = showProgressBar();

				fetch(
					_makeUrl('/portal/access-key-confirmation'),
					defaultPostOptions({
						body: new URLSearchParams({
							accessKey: options.sessionKey,
						}),
					})
				).then(responseToJson()).then(data => {
					hideProgressBar();

					if (data.error) {
						instructionsContainer.innerHTML = data.msg;
						sessionStartFailed();
					} else {
						if (data.confirm) {
							const message = document.createElement('p');
							message.textContent = data.confirmationMessage;

							const yesButton = document.createElement('button');
							yesButton.id = 'confirmKey';
							yesButton.textContent = data.yes;
							yesButton.addEventListener('click', () => {
								options.skipConfirm = true;
								that.startSession(options);
							}, { once: true });

							const cancelButton = document.createElement('button');
							cancelButton.id = 'cancelKey';
							cancelButton.textContent = data.cancel;
							cancelButton.addEventListener('click', () => {
								closeDialog();
							}, { once: true });

							const centeringDiv = document.createElement('div');
							centeringDiv.style.textAlign = 'center';
							centeringDiv.appendChild(message);
							centeringDiv.appendChild(yesButton);
							centeringDiv.appendChild(cancelButton);

							instructionsContainer.replaceChildren();
							instructionsContainer.appendChild(centeringDiv);
						} else {
							options.skipConfirm = true;
							that.startSession(options);
						}
					}
				});

				return false;
			}

			if (isPlainObject(options.rep) && !options.skipConfirm) {
				const hideProgressBar = showProgressBar();

				// Ensure the given rep id and name are valid, and that the rep is still online.
				fetch(
					_makeUrl('/portal/check-rep'),
					defaultPostOptions({
						body: new URLSearchParams({
							id: options.rep.id,
							name: options.rep.name,
						}),
					})
				).then(responseToJson()).then(data => {
					hideProgressBar();

					if (data.error) {
						instructionsContainer.innerHTML = data.msg;
						sessionStartFailed();
					} else {
						options.skipConfirm = true;
						that.startSession(options);
					}
				});

				return false;
			}

			that.downloadWithJavascript(downloadParams);
		};

		/**
				@public
				Initiate a client download using JavaScript to change the window location to
				the download URL.

				@param {String|Object} parameters
		 */
		this.downloadWithJavascript = function(parameters) {
			console.log('Using javascript delivery');
			const src = (typeof parameters === 'object' ? parameters.fallbackURL : parameters);

			parameters.sessionParameters['g-public-errorType'] = 'json';

			if (!_isSameOrigin && clientHintsSupported) {
				parameters.sessionParameters['platform'] = _platformId;
			}

			const hideProgressBar = showProgressBar();

			fetch(
				_makeUrl('/portal/instructions/customer'),
				defaultPostOptions({
					body: urlEncodeObject(parameters.sessionParameters),
				})
			).then(responseToJson()).then(data => {
				hideProgressBar();

				if (data.error) {
					instructionsContainer.innerHTML = data.errorMessage;
				} else {
					// See http://b/21179
					if (_isAndroid || _isIOS) {
						if (_fallbackToFull === false) {
							// Easier to remove it here than to prevent its creation and check for its
							// existence in a million places.
							if (removeDialog) {
								removeDialog();
							}
						}
					} else {
						instructionsContainer.innerHTML = data.instructions;

						const fallbackLink = document.getElementById('fallbackLink');

						if (fallbackLink) {
							fallbackLink.href = src + '&download=1';
						}
					}

					const download = (!_isAndroid && !_isIOS) ? '&download=1' : '';
					let timeoutID;

					if (data.startPinned) {
						const onBlur = () => {
							clearTimeout(timeoutID);
							window.removeEventListener('blur', onBlur);
						};

						window.addEventListener('blur', onBlur);

						timeoutID = setTimeout(function() {
							window.removeEventListener('blur', onBlur);
							downloadUrl(src + download);
						}, 2000);

						console.log("Attempting to signal a Jump Client to start");
						if (_uaParser.isSafari()) {
							window.location = src + download + '&d=1';
						} else {
							downloadUrl(src + download + '&d=1');
						}
					} else {
						downloadUrl(src + download);
					}
				}
			});
		};

		function downloadUrl(url, params) {
			if (!url.includes('download=1')) {
				window.location.href = url;
				return;
			}

			// Create a hidden form, so we can do a POST request that triggers the download straight
			// to the browser's download manager without changing the current window location.
			// An XHR POST would require reading the file response into memory, which could crash
			// the browser if the file is large. Also, this download must work cross-origin, so
			// a[download] won't work.
			const form = document.createElement('form');
			form.method = 'post';
			form.action = url;

			if (params) {
				for (const [ key, value ] of Object.entries(params)) {
					const paramInput = document.createElement('input');
					paramInput.type = 'hidden';
					paramInput.name = key;
					paramInput.value = value;
					form.appendChild(paramInput);
				}
			}

			// The form must be embedded in some document for the submit() to work. We are using an
			// iframe so the form submission doesn't cause the parent page to navigate to the form
			// action URL, which would unload the entire application.
			const iframe = document.createElement('iframe');
			iframe.style.display = 'none';
			document.body.appendChild(iframe);

			iframe.contentDocument.body.appendChild(form);
			form.submit();
		}

		/***************************************************************
		 * Chat methods
		 ***************************************************************/

		/**
				@private
				Builds the click to chat URL using the given parameter map.
				@param {Object} params Session start parameters.
				@param {Boolean} forPopup Indicates whether the chat page should be loaded in
					a new window.
		 */
		function createURL(params, forPopup) {
			// These two are more complicated, so we can't use urlEncodeObject.
			const renderedAttributes = renderAttributes(params);
			const renderedSkills = renderSkills(params);
			delete params.attributes;
			delete params.skills;
			const qStr = 'popup=' + (forPopup ? '1' : '0')
				+ '&c2cjs=1'
				+ '&' + urlEncodeObject(params)
				+ renderedAttributes
				+ renderedSkills;
			return getProtocolHost() + '/api/start_session?' + qStr;
		}

		/**
				@private
				Redirects the current window to a click to chat URL built using the given
				params.
				@param {Object} params Session start parameters.
		 */
		function redirectWindow(params) {
			window.location.href = createURL(params, false);
		}

		let previousChatWindow;

		/**
			 @private
			 Start a click to chat session using the given params. Opens in a new window
			 if possible. Otherwise it redirects the current window if doFull is true.
			 @param {Object} params Session start parameters.
			 @param {Boolean} doFull If this is true and we can't open a new window,
			 perhaps because of popup blockers, we try to redirect the current window
			 to the chat page.
		 */
		function startChat(params, doFull) {
			if (previousChatWindow && !previousChatWindow.closed && previousChatWindow.location.href.includes('/chat/html')) {
				previousChatWindow.focus();
				return;
			}

			const chatWindow = previousChatWindow = (
				_uaParser.isMobile()
					? null
					: window.open(
						createURL(params, true),
						'clickToChat',
						'toolbar=no,directories=no,status=no,menubar=no,resizable=yes,location=no,scrollbars=yes,width=640,height=480',
					)
			);

			if (!chatWindow && doFull) {
				redirectWindow(params);
			}
		}

		/**
			 @public
			 Start a chat session with the given options. Called from start_session.js.
			 @param {Object} options Determines how the session will be started, i.e.
			 session key, issue, rep.
		 */
		this.startChatSession = function(options) {
			console.log('Starting chat session from inside core');

			const params = getSessionParams(options);

			startChat(params, _fallbackToFull);
		};

		/***************************************************************
		 * Common methods
		 ***************************************************************/

		/**
				@private
				Does basic validation to ensure that one and only one start method has been
				provided.
		 */
		function basicValidation(options) {
			const methodCount = [ 'rep', 'issue', 'sessionKey' ]
				.reduce((count, method) => {
					if (options.hasOwnProperty(method)) {
						return count + 1;
					}

					return count;
				}, 0);

			if (methodCount > 1) {
				// They have already got another start method specified
				throw new Error('Only one start method is allowed in the options passed in to start(): either "rep", "sessionKey", or "issue".');
			}

			if (methodCount === 0) {
				throw new Error('A start method is required in the options object passed to start(): either "rep", "sessionKey", or "issue".');
			}
		}

		function isNumberOrString(thing) {
			return isFiniteNumber(thing) || isString(thing);
		}

		/**
				@private
				Accepts session options in the publicly-documented format, validates them,
				and massages them into the format expected by the server.

				@param {Object} options Session options provided by external code.
				@returns {Object} session params in format expected by the server
		 */
		function getSessionParams(options) {
			basicValidation(options);

			let params = {};

			if (isPlainObject(options.rep)) {
				// It's a rep session
				if ((!isNumberOrString(options.rep.id)) || !isString(options.rep.name)
					|| options.rep.id.toString().trim() === ''
					|| options.rep.name.trim() === '') {
					throw new Error('Both id and name are required parameters when starting a session with a rep.');
				}

				params = {
					id: options.rep.id.toString().trim(),
					name: options.rep.name.trim(),
				};
			} else if ('issue' in options) {
				// options.issue can be assigned, but still null or undefined, probably due to a bad
				// element id in the user's code.

				if (options.issue === null) {
					throw new Error("'issue' option cannot be null");
				}

				if (typeof options.issue === 'undefined') {
					throw new Error("'issue' option cannot be undefined");
				}

				if (!isPlainObject(options.issue) && !isFormElement(options.issue)) {
					throw new Error("'issue' option must be a form element reference or a plain object");
				}

				// It's an issue submission, but we must also check if issue is a form
				// element or generic object.
				if (isFormElement(options.issue)) {
					params = formToObject(options.issue);
					// FIXME: the server totally ignores the skills sent with issue-based session
					//  starts and simply uses the skills that are already associated with the issue
					//  in the DB by the admin in /login. Therefore this whole if block is pointless
					//  (and buggy) and the public docs should be updated to say that skills are
					//  ignored for issue requests.
					if (params.hasOwnProperty('skills')) {
						// Convert from the form element's comma-separated format
						// to an array, then append any skills that were also specified
						// in the options object.
						params.skills = [
							...params.skills.split(','),
							...(Array.isArray(options.skills) ? options.skills : []),
						];
						// Remove duplicate skills
						params.skills = uniq(params.skills);
						// Set skills on the options object to simulate the skills coming
						// from the javascript object instead of the HTML form.
						options.skills = params.skills;
						delete params.skills;
					}
				} else {
					// Clone to avoid clobbering issue object of caller.
					params = BG.clone(options.issue);
				}

				if (!('id' in params) && !('codeName' in params)) {
					// If neither is provided
					throw new Error('No issue id was supplied in the options passed to start()');
				}

				if (('id' in params) && ('codeName' in params)) {
					// If both are provided
					throw new Error('Only id or codeName may be provided, not both');
				}

				if ('id' in params) {
					if (params.id === null) {
						throw new Error('A null issue id was supplied in the options passed to start(). The issue id should be a number.');
					}

					if (typeof params.id === 'undefined') {
						throw new Error('An undefined issue id was supplied in the options passed to start(). The issue id should be a number.');
					}

					if (!isNumberOrString(params.id) || params.id.toString().trim() === '') {
						throw new Error('An invalid issue id was supplied in the options passed to start(). The issue id should be a number.');
					}
				}

				if ('codeName' in params) {
					if (params.codeName === null) {
						throw new Error('A null issue codeName was supplied in the options passed to start(). The issue codeName should be a string.');
					}

					if (typeof params.codeName === 'undefined') {
						throw new Error('An undefined issue codeName was supplied in the options passed to start(). The issue codeName should be a string.');
					}

					if (!isNumberOrString(params.codeName)) {
						throw new Error('An invalid issue codeName was supplied in the options passed to start(). The issue codeName should be a string.');
					}

					if (params.codeName.toString().trim() === '') {
						throw new Error('An empty issue codeName was supplied in the options passed to start(). The issue codeName should be a non-empty string.');
					}

					// In case it was a number:
					params.codeName = params.codeName.toString();
				}

				params = BG.remapIssueSessionParams(params);

				params.issue_menu = '1';
			} else if (options.hasOwnProperty('sessionKey')) {
				params = {
					short_key: options.sessionKey,
					skipconfirm: 1,
				};
			}

			if (!isPlainObject(options.attributes)) {
				options.attributes = {};
			}

			if (!Array.isArray(options.skills)) {
				options.skills = [];
			}

			// Ignore external keys in correct location but with an invalid type.
			if (!isString(options.attributes.external_key) && !isFiniteNumber(options.attributes.external_key)) {
				delete options.attributes.external_key;
			}

			if (!options.attributes.external_key) {
				// Move external keys from locations and casings used by older API versions.
				if (isString(options.external_key) || isFiniteNumber(options.external_key)) {
					options.attributes.external_key = options.external_key.toString();
					delete options.external_key;
				} else if (isString(options.attributes.externalKey) || isFiniteNumber(options.attributes.externalKey)) {
					options.attributes.external_key = options.attributes.externalKey.toString();
					delete options.attributes.externalKey;
				}
			}

			params.locale = isString(options.locale)
				? options.locale.trim() || null
				: null;

			const chatLocale = options.chat_locale ?? options.chatLocale;

			params.chat_locale = isString(chatLocale)
				? (chatLocale.trim() || null)
				: null;

			if (isString(options.timezone_offset) || isFiniteNumber(options.timezone_offset)) {
				params.timezone_offset = options.timezone_offset;
			} else {
				params.timezone_offset = new Date().getTimezoneOffset() * -1;
			}

			if (isPlainObject(options.customer)) {
				Object.entries(options.customer)
					.filter(([ _, value ]) => value !== undefined && value !== null)
					.forEach(([ customerKey, value ]) => {
						params[`customer.${customerKey}`] = value;
					});
			}

			params.attributes = Object.entries(options.attributes)
				// Omit undefined and null attributes.
				.filter(([ _, value ]) => value !== undefined && value !== null)
				.reduce((result, [ key, value ]) => {
					result[key] = value;
					return result;
				}, {});

			// Filter out undefined, null, and empty string.
			params.skills = options.skills
				.filter(skill => isString(skill) && skill.trim().length > 0)
				.map(skill => skill.trim());

			if (_isIOS) {
				params.platform = 'ios';
			}

			return params;
		}

		/************
		 * Dialog controls
		 ************/

		/**
				@private
				Displays the dialog on screen, and sets it's contents.
		 */
		function loadDialog(contents) {
			let modalOverlay = document.querySelector('.bg-modal-dialog-overlay');

			if (!modalOverlay) {
				modalOverlay = document.createElement('div');
				modalOverlay.className = 'bg-modal-dialog-overlay opaque';

				modalOverlay.addEventListener('click', () => {
					if (_allowDialogClose) {
						closeDialog();
					}
				}, { once: true });
			}

			let dialogRoot = document.getElementById('bomgarDialog');

			if (!dialogRoot) {
				dialogRoot = document.createElement('div');
				dialogRoot.className = 'bg-dialog-root';
				dialogRoot.id = 'bomgarDialog';
				dialogRoot.setAttribute('tabindex', '-1');
				dialogRoot.setAttribute('role', 'dialog');
			} else {
				dialogRoot.replaceChildren();
			}

			const dialogContent = document.createElement('div');
			dialogContent.className = 'bg-dialog-content';
			dialogRoot.appendChild(dialogContent);

			const dialogElem = document.createElement('div');
			dialogContent.appendChild(dialogElem);

			document.body.appendChild(modalOverlay);
			document.body.appendChild(dialogRoot);

			removeDialog = () => {
				dialogRoot.remove();
				modalOverlay.remove();
			};

			if (!_allowDialogClose) {
				document.body.style.overflow = 'hidden';
			}

			dialogElem.appendChild(contents);
		}

		function closeDialog() {
			if (_closeOnFinish) {
				if (removeDialog) {
					removeDialog();
				}
			}
		}

		/**
				@private
				Returns a URL containing the current location's protocol and the user-
				configured site address.

				@returns {String} something like "protocol://site.address.com"
		 */
		function getProtocolHost() {
			return _session.getProtocol() + '//' + _sessionHost;
		}

		/**
				@private
				Returns an object with name=>value mappings for input elements of a
				form. Assumes you've already verifed the parameter is a form element.

				@param {HTMLFormElement} formElement
				@returns {Object}
		 */
		function formToObject(formElement) {
			const tagNames = [ 'input', 'select', 'textarea' ];
			const badTypes = [ 'submit', 'button', 'image', 'reset', 'file' ];
			const checkable = [ 'checkbox', 'radio' ];

			return Array.from(formElement.elements)
				.filter(el => {
					return el.name
						&& !el.disabled
						&& tagNames.includes(el.tagName.toLowerCase())
						&& !badTypes.includes(el.type.toLowerCase())
						&& (el.checked || !checkable.includes(el.type));
				})
				.reduce((params, el) => {
					params[el.name] = el.value;
					return params;
				}, {});
		}
	}; // end Core class
}());

function isFormElement(obj) {
	return !!obj
		&& !!obj.tagName
		&& obj.tagName.toLowerCase() === 'form';
}
