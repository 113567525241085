/*global BG*/
import { isPlainObject } from '@beyondtrust-sra/typescript-utils';
import { Core } from './core';
import { clientHintsSupported, ClientHints, UserAgentStringParser } from '@bomgar/user-agent-detector';
import { defaultPostOptions } from './defaultPostOptions';
import { makeUrl } from './makeUrl';
import { responseToJson } from './responseToJson';

// Create our namespace if it's not there already.
if (typeof window.BG === 'undefined') {
	window.BG = {};
}

(function() {
	'use strict';

	BG.START_TYPE = {
		BROWSER: 1,
		CHAT: 2,
		FULL: 3,
	};

	BG.clone = function(obj) {
		if (obj === null || typeof obj !== 'object') {
			return obj;
		}

		const temp = obj.constructor(); // changed

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				temp[key] = BG.clone(obj[key]);
			}
		}
		return temp;
	};

	/**
		@class
		@private
		Exposes publicly-documented API methods for starting sessions. Handles loading
		external dependencies only when a session is requested.
	 */
	function Session() {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const that = this;
		let _host = window.location.host;
		let _protocol = null;
		let _core = null;
		const _uaParser = this.uaParser = getUAParser();

		const _isIOS = _uaParser.isiOS();
		const _isAndroid = _uaParser.isAndroid();
		let cssLoaded = false;

		function loadResources() {
			if (!cssLoaded) {
				cssLoaded = true;

				const link = document.createElement('link');
				const head = document.getElementsByTagName('head')[0];
				link.type = 'text/css';
				link.href = _makeUrl('/content/start_session.css');
				link.rel = 'stylesheet';

				head.appendChild(link);
			}

			if (!_core) {
				_core = new Core(that);
			}
		}

		/**
			@public
		 */
		this.getHost = function() {
			return _host;
		};

		this.getProtocol = function() {
			// Lazy init _protocol.
			if (_protocol === null) {
				// In case the page is loaded from file:// for testing or demo purposes.
				if (window.location.protocol.indexOf('http') !== 0) {
					_protocol = 'http:';
				} else {
					_protocol = window.location.protocol;
				}
			}
			return _protocol;
		};

		/* Documented Public API methods */

		/**
			@public
			Set the address of the support site you wish to connect to.
			See API documentation for details.

			@param {String} site Hostname of the support site.
		 */
		BG.setSite = function(site) {
			_host = site;
		};

		/**
			@public
			Set the protocol to use for API requests to the appliance.
			See API documentation for details.

			@param {String} protocol "http" or "https" only
		 */
		BG.setProtocol = function(protocol) {
			const temp = protocol.trim().toLowerCase();

			if (temp === 'http' || temp === 'https') {
				_protocol = temp;
			} else {
				throw new Error('protocol must be either "http" or "https"');
			}
		};

		const _makeUrl = (path, query) => makeUrl(that.getProtocol(), that.getHost(), path, query);

		BG.isAuthenticated = (abortSignal) => {
			loadResources();

			const options = abortSignal ? { signal: abortSignal } : {};

			return fetch(
				_makeUrl('/portal/saml-authentication-confirmation'),
				defaultPostOptions(options),
			).then(responseToJson()).then(
				data => !data.error,
				(e) => {
					// If the abortSignal is aborted, we rethrow its error / reason for someone else
					// to deal with, and so ensureAuthenticated() does not reload the page just
					// because it was cancelled by its caller.
					if (abortSignal.aborted) {
						return Promise.reject(e);
					}

					return false;
				}
			);
		};

		BG.ensureAuthenticated = (abortSignal) => {
			return BG.isAuthenticated(abortSignal).then(authenticated => {
				if (!authenticated) {
					window.location.reload();
				}

				return authenticated;
			});
		};

		// Converts an object of param key/values into the keys/values that the server expects
		// WARNING: This is also used inside issue_form.js on the portal.
		BG.remapIssueSessionParams = function(params) {
			params = BG.clone(params);

			const options = {};

			let remap = {
				customerName: 'customer_name',
				companyCode: 'customer_company_code',
				companyName: 'customer_company',
				details: 'customer_desc',
				externalKey: 'external_key',
			};

			for (const oldKey in remap) {
				if (remap.hasOwnProperty(oldKey)) {
					if (params.hasOwnProperty(oldKey)) {
						params[remap[oldKey]] = params[oldKey];
						delete params[oldKey];
					}
				}
			}

			function remapCustomerFields(map) {
				for (const oldKey in map) {
					if (map.hasOwnProperty(oldKey)) {
						if (params.hasOwnProperty(oldKey)) {
							if (typeof options.customer !== 'object') {
								options.customer = {};
							}
							if (!options.customer.hasOwnProperty(map[oldKey])) {
								options.customer[map[oldKey]] = params[oldKey];
							}
							delete params[oldKey];
						}
					}
				}
			}

			// Then the second remap turns the old way into the new way. We have to
			// be careful not to overwrite the wrong data though. issue.customer* has
			// lower priority than the customer.* data, so we need to check to see
			// if the customer data is filled out already, then clear that. Either
			// way, the issue object will only contain the issue id when we're done.
			remap = { customer_name: 'name', customer_company_code: 'company_code',
				customer_company: 'company', customer_desc: 'details' };

			remapCustomerFields(remap);

			// We've renamed internal fields to be prefixed with 'internal.'
			// So we need to update them to what the rest of the codebase expects
			remap = {
				'internal.customer_name': 'name',
				'internal.customer_company': 'company',
				'internal.customer_company_code': 'company_code',
				'internal.customer_details': 'details',
			};

			remapCustomerFields(remap);

			if (options.hasOwnProperty('customer')) {
				// Remap the customer properties into the params object
				for (const customerKey in options.customer) {
					params['customer.' + customerKey] = options.customer[customerKey];
				}
			}

			return params;
		};

		/**
			@public
			Start a session of the given type with the given options.
			See API documentation for details.

			@param {BG.START_TYPE} type
			@param {Object} [options]
		 */
		BG.start = function(type, options) {
			if (!Object.values(BG.START_TYPE).includes(type)) {
				throw new Error('"type" must be a valid START_TYPE, for instance, BG.START_TYPE.FULL.');
			}

			if (!isPlainObject(options)) {
				throw new Error('"options" must be a plain object that contains the start options.');
			}

			if (type === BG.START_TYPE.CHAT && (_isIOS || _isAndroid) && options.uiOptions?.fallbackToFullWindow === false) {
				type = BG.START_TYPE.FULL;
			}

			loadResources();

			switch (type) {
				case BG.START_TYPE.CHAT:
					_core.startChatSession(options);
					break;
				case BG.START_TYPE.FULL:
					_core.startFullSession(options);
					break;
			}

			return true;
		};
	} // class Session

	delete BG.session;
	BG.session = new Session();

	function getUAParser() {
		return clientHintsSupported
			? new ClientHints(navigator.userAgentData)
			: new UserAgentStringParser(navigator.userAgent);
	}
}());
