'use strict';

import { urlEncodeObject } from './urlEncodeObject';

/**
 *
 * @param {string} protocol
 * @param {string} host
 * @param {string} path
 * @param {object} queryObj
 * @returns {string}
 */
export function makeUrl(protocol, host, path, queryObj) {
	protocol = protocol.endsWith(':') ? protocol : `${protocol}:`;

	const url = new URL(protocol + '//' + host);

	if (path) {
		url.pathname = path;
	}

	if (queryObj) {
		url.search = urlEncodeObject(queryObj);
	}

	return url.toString();
}
