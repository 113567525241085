'use strict';

export function responseToJson() {
	return response => {
		if (!response.ok) {
			console.error(`Received ${response.statusCode} error response from request to ${response.url}`);
			return Promise.reject(response);
		}

		return response.json();
	};
}
