'use strict';

/**
 * @param {RequestInit} requestOptions
 * @returns {RequestInit}
 */
export function defaultPostOptions(requestOptions) {
	// We send an x-www-form-urlencoded body because that's what the old jQuery code did by
	// default – not for any good reason.
	// TODO: use a JSON request body

	return {
		method: 'POST',
		headers: [
			[ 'content-type', 'application/x-www-form-urlencoded; charset=UTF-8' ],
			[ 'accept', 'application/json, text/javascript' ],
		],
		...requestOptions,
	};
}
